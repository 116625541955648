<template>
<div class="price-mian">
	<div :class="['currency','fontColMinor',+fontSize<=28?'font20':'font24']">￥</div>
	<div :class="['int','fontBold fontColMinor',+fontSize<=28?'font28':('font'+fontSize)]">{{int}}</div>
	<div :class="['decimal','fontColMinor',+fontSize<=28?'font28':'font24']">.{{decimal}}</div>
	<div :class="['decimal','fontColMinor',+fontSize<=28?'font20':'font24']"> &nbsp; {{unit}}</div>
</div>
</template>

<script>
export default {
	props: {
		price: {
			type: [String, Number],
			default: '0.00'
		},
		fontSize: {
			type: [String, Number],
			default: '44'
		},
		unit: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			int: '0',
			decimal: '00'
		}
	},
	computed: {
		onPrice() {
			return this.price
		}
	},
	watch: {
		onPrice: function(n,o) {
			this.onFormat(n)
		}
	},
	created() {
		this.onFormat(this.price)
	},
	methods: {
		onFormat(val) {
			let price = val.toString();
			if(price.indexOf('.') != -1) {
				let arr = price.split('.')
				this.int = arr[0]
				this.decimal = arr[1]
			}
			else {
				this.int = price
				this.decimal = '00'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.price-mian{
	display: flex;
	align-items: flex-end;
	// justify-content: center;
	.currency,.decimal{
		line-height: 38px;
	}
	.currency{
		transform-origin: right center;
	}
	.int{
		padding-left: 4px;
		padding-right: 4px;
	}
}
</style>
