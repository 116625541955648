<template>
	<div>
		<div class="slice-pic-box">
			<img src="@/components/agreement/grys/pic/pa/1.jpg" />
			<img src="@/components/agreement/grys/pic/pa/2.jpg" />
			<img src="@/components/agreement/grys/pic/pa/3.jpg" />
			<img src="@/components/agreement/grys/pic/pa/4.jpg" />
			<img src="@/components/agreement/grys/pic/pa/5.jpg" />
			<img src="@/components/agreement/grys/pic/pa/6.jpg" />
			<img src="@/components/agreement/grys/pic/pa/7.jpg" />
			<img src="@/components/agreement/grys/pic/pa/8.jpg" />
			<img src="@/components/agreement/grys/pic/pa/9.jpg" />
		</div>
	</div>
</template>
<script>
export default{
	props: {
		companyCode: {  // 保司编码
			type: [Number,String],
			default: 9818,
		},
	}
}
</script>

<style lang="less">
.slice-pic-box{
	width: 100%;
	display: flex;
	flex-direction: column;
}
</style>
