<template>
	<div>
		<div class="slice-pic-box">
			<img src="@/components/agreement/tbyd/pic/1.jpg" />
			<img src="@/components/agreement/tbyd/pic/2.jpg" />
			<img src="@/components/agreement/tbyd/pic/3.jpg" />
			<img src="@/components/agreement/tbyd/pic/4.jpg" />
			<img src="@/components/agreement/tbyd/pic/5.jpg" />
			<img src="@/components/agreement/tbyd/pic/6.jpg" />
			<img src="@/components/agreement/tbyd/pic/7.jpg" />
			<img src="@/components/agreement/tbyd/pic/8.jpg" />
		</div>
	</div>
</template>
<script>
export default{
	props: {
		companyCode: {  // 保司编码
			type: [Number,String],
			default: 9818,
		},
	}
}
</script>

<style lang="less">
.slice-pic-box{
	width: 100%;
	display: flex;
	flex-direction: column;
}
</style>
