<template>
<div class="insureForm-main">
	<div class="relation-box" v-if="formType == 'insureds'">
		<template v-for="(it,inx) in relationship">
			<div :class="['tags-item',tagsDisabled(it.value),formData.relationWithProposer==it.value?'active':'']" @click="changeTags(it.value,it.text)">{{it.text}}</div>
		</template>
	</div>
	<van-form ref="insureForm" class="insureForm-box">
		<van-cell-group :border="false" :class="{proposerForm: formType == 'proposer'}">
			<van-field v-if="formData.relationWithProposer != 1" :disabled="disabled" v-model.trim="formData.name" label-width="72" label="姓名" input-align="right" placeholder="请填写真实姓名" @blur="e => {onNameBlur(formData.name,e)}" :rules="[{ required: true, trigger: 'onChange' }]" />
			<!-- <van-field v-if="formData.relationWithProposer != 1" v-model.trim="formData.identityCode" label-width="72" label="身份证号" input-align="right" placeholder="请填写身份证号" :rules="[{ validator: validIdCard, trigger: 'onChange' }]" /> -->
			<van-field v-if="formData.relationWithProposer != 1" :disabled="disabled" v-model.trim="formData.identityCode" name="identityCode" label-width="100" input-align="right" clearable placeholder="请填写证件号码" :rules="[{required: true, validator: validIdCard, trigger: 'onChange' }]" @input="idCardInput">
				<div slot="label" class="specialLabel" @click="cardTypePopup = !disabled">
					<span>{{formData.identityType | cardType}}</span>
					<van-icon name="arrow-down" />
				</div>
			</van-field>
			<van-field v-if="formData.relationWithProposer != 1 && (this.formData.identityType === 2 || this.formData.identityType === 19)" :disabled="disabled" v-model.trim="formData.nationality" readonly label-width="72" label="国籍" input-align="right" placeholder="请选择国籍" :rules="[{ required: true, trigger: 'onChange' }]"
			  @click="nationalityPopup = true">
			  <template #input>
			    <div v-if="formData.nationality" :value="formData.nationality" class="embeddedInput">
			      {{formData.nationality | country}}
			    </div>
			  </template>
			</van-field>
			<van-field v-if="formData.relationWithProposer != 1 && this.formData.identityType !== 11" :disabled="disabled" v-model.trim="formData.gender" label-width="72" label="性别" input-align="right">
				<template slot="input">
					<van-radio-group v-model="formData.gender" direction="horizontal">
						<van-radio name="1" icon-size="16px">男</van-radio>
						<van-radio name="2" icon-size="16px">女</van-radio>
					</van-radio-group>
				</template>
			</van-field>
			<van-field v-if="formData.relationWithProposer != 1 && this.formData.identityType !== 11" :disabled="disabled" v-model.trim="formData.birthday" readonly @click="dateTimePopup = true" label-width="72" label="出生日期" input-align="right" placeholder="请选择出生日期" :rules="[{ required: true, trigger: 'onChange' }]"/>
			<van-field v-if="formData.relationWithProposer != 1" :disabled="disabled" v-model.trim="formData.mobile" :formatter="mobileFormatter" maxlength="11" label-width="72" label="手机号" input-align="right" type="tel" placeholder="请填写手机号" :rules="[{ validator: mobileRules, trigger: 'onChange' }]" />
			<!-- <van-field v-if="formType == 'insureds'" :disabled="disabled" label-width="88" label="被保人身份" input-align="right" :error-message="ishealthCareErr&&!formData.socialInsuranceType&&formData.socialInsuranceType!=0?'当前资质校验不通过，请重新选择参保类型':''">
				<template #input>
					<van-dropdown-menu class="dropdown-menu-box">
						<van-dropdown-item v-model="formData.socialInsuranceType" :disabled="disabled" :options="healthCare" @change="onHealthChange" :rules="[{ required: true, trigger: 'onChange' }]"/>
						<div class="placeholder" v-if="ishealthCareErr&&!formData.socialInsuranceType&&formData.socialInsuranceType!=0">请选择参保类型</div>
					</van-dropdown-menu>
				</template>
			</van-field> -->
			<van-field v-if="formType == 'insureds'" :disabled="disabled" v-model.trim="formData.socialInsuranceType" readonly label-width="88" label="被保人身份" input-align="right" placeholder="请选择被保人身份" :rules="[{ required: true, trigger: 'onChange' }]"
			  @click="socialTypePopup = true">
			  <template #input>
			    <div v-if="formData.socialInsuranceType" :value="formData.socialInsuranceType" class="embeddedInput">
			      {{formData.socialInsuranceType | socialType(healthCare)}}
			    </div>
			  </template>
			</van-field>
		</van-cell-group>
	</van-form>
	<van-popup v-model="cardTypePopup" round position="bottom" :style="{ height: '40%' }">
		<van-picker
		  title="证件选择"
		  show-toolbar
		  :columns="identityTypes"
		  @confirm="val => {onConfirm(val, 'card')}"
		  @cancel="onCancel"
		/>
	</van-popup>
	<van-popup v-model="nationalityPopup" round position="bottom" :style="{ height: '48%' }">
		<van-picker
		  title="国籍选择"
		  show-toolbar
		  :columns="nationality"
		  @confirm="val => {onConfirm(val, 'nationality')}"
		  @cancel="onCancel"
		/>
	</van-popup>
	<van-popup v-model="socialTypePopup" round closeable close-icon-position="top-left" position="bottom" :style="{ height: '360px' }">
		<div class="socialTypePopup-box">
			<div class="popup-tit font32 fontCol3 fontBold">选择被保人身份</div>
			<div class="content-box">
				<div class="intro-box">
					<div class="intro-tit font28 fontCol9 fontSingleLine">
						<van-icon name="question-o" />
						什么是基本医保/中央公费医疗/新市民/征地超转等其他医保？
					</div>
					<van-popover v-model="showPopover" theme="dark" trigger="click" placement="bottom-end">
						<div class="intro-text">
							基本医保：本市基本医疗保险在保状态
							的参保人员 (包括城镇职工医保、城乡
							居民医保) <br><br>
							
							中央公费医疗：享受中央公费医疗待遇
							人群 <br><br>
							
							新市民：拥有北京户籍或拥有北京市居
							住证且已参加异地基本医保的北京市新
							市民 <br><br>
							
							征地超转等其他医保情况：指国家建设
							征地农民户转为居民户的原农村劳动力
							中年龄超过转工安置年限的人员及其他
							符合医疗保险为在保状态的人员。
						</div>
					  <template #reference>
					    <div class="look font28 fontCol3">
					    	查看<van-icon name="arrow-down" />
					    </div>
					  </template>
					</van-popover>
				</div>
				<div class="socialType-select-box">
					<div class="font30 fontCol3 marginT44">请选择您的身份</div>
					<template v-for="item in healthCare">
						<div class="socialType-item font28 fontCol3" :key="item.value" @click="openIntroPopup(item)">
							{{item.text}}
						</div>
					</template>
				</div>
			</div>
		</div>
	</van-popup>
	<van-popup v-model="introPopup" round :style="{width: '88%', height: '360px' }">
		<div class="introPopup-box">
			<div class="popup-tit textAlignC font32 fontCol3 fontBold">请确认符合{{introInfo.text}}<br>身份并参保</div>
			<div class="intro-detail font28">{{introInfo.intro}}</div>
			<div class="agreement font28 fontCol6">请阅读并确认<span class="fontRed" @click="openFilePopup(120)">《参保资格承诺书》</span></div>
			<div class="option-box">
				<van-button color="#ee0a24" round plain @click="introPopup = false">暂不投保</van-button>
				<van-button color="linear-gradient(to right, #ff6034, #ee0a24)" round @click="introConfirm"> 确定 </van-button>
			</div>
		</div>
	</van-popup>
	<van-popup v-model="dateTimePopup" round position="bottom" :style="{ height: '48%' }">
		<van-datetime-picker
		  type="date"
		  title="选择出生日期"
			:min-date="minDate"
		  :max-date="maxDate"
			v-model="currentDate"
			@confirm="val => {onConfirm(val, 'date')}"
			@cancel="onCancel"
		/>
	</van-popup>
</div>
</template>

<script>
import moment from 'moment';
import { identityTypes,relationship,healthCareRS,healthCarePA,healthCareRB,agreementList,insuredsData,nationality } from '@/utils/dictionary.js'
import {checkIdCard} from '@/utils/Idcard.js'
export default {
	model: {
		prop: 'propData',
		event: 'change'
	},
	props: {
		formType: {  // 信息对象类型 'proposer':投保人信息 'insureds':被保人信息
			type: String,
			default: 'proposer',
		},
		insureds: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: () => false
		},
		companyCode: {  // 保司编码
			type: [Number,String],
			default: 9803,
		},
		proposer: {
			type: Object,
			default: () => {}
		},
		propData: {  // 表单数据
			type: Object,
			default: () => {}
		},
		relationList: {  // 关系人占用
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			relationship: relationship,
			identityTypes: identityTypes,
			nationality: nationality,
			healthCareRS: healthCareRS,
			healthCarePA: healthCarePA,
			healthCareRB: healthCareRB,
			formData: {},
			password: '',
			ishealthCareErr: false,
			cardTypePopup: false,
			nationalityPopup: false,
			socialTypePopup: false,
			introPopup: false,
			introInfo: {
				text: '',
				value: '',
				intro: ''
			},
			showPopover: false,
			dateTimePopup: false,
			minDate: new Date(1920, 0, 1),
			maxDate: new Date(),
			currentDate: new Date(2000, 0, 1)
		}
	},
	computed: {
		healthCare() {
			if(+this.companyCode == 9803) {  // 中国平安
				return this.healthCarePA
			}
			if(+this.companyCode == 9818) {  // 中国人寿
				return this.healthCareRS
			}
			if(+this.companyCode == 9801) {  // 中国人保
				return this.healthCareRB
			}
		}
	},
	filters: {
		cardType (val) {
			if (!val) return undefined
			let it = identityTypes.filter(item => +item.value === +val)
			return it[0].text
		},
		country (val) {
			if (!val) return undefined
			let it = nationality.filter(item => item.value === val)
			return it[0].text
		},
		socialType (val, healthCare) {
			if (!val) return undefined
			let it = healthCare.filter(item => +item.value === +val)
			console.log('socialType:', it)
			return it[0].text
		},
	},
	watch: {
		healthCare(n,o) {
			if(this.formType == 'insureds') {
				if(n[0].code != 9801 || o[0].code != 9801) this.$set(this.formData,'socialInsuranceType',n.length?n[0].value:undefined)
			}
		},
	},
	mounted() {
		this.formData = this.propData
	},
	methods: {
		onConfirm (val, type) {
			if (type === 'card') {
				this.cardTypePopup = false
				if (this.formData.identityType !== val['value']) {
					this.formData.identityType = val['value']
					this.formData.identityCode = ''
					this.formData.nationality = ''
					this.formData.gender = 1
					this.formData.birthday = ''
				}
				if (val['value'] === 11) this.formData.nationality = 'CHN' // 选择身份证默认国籍中国
			} else if (type === 'date') {
				let date = moment(val).format('YYYY-MM-DD');
				this.formData.birthday = date
				this.dateTimePopup = false
			} else if (type === 'nationality') { 
				this.formData.nationality = val['value']
				this.nationalityPopup = false
			}
		},
		onCancel () {
			this.cardTypePopup = false
			this.dateTimePopup = false
			this.nationalityPopup = false
		},
		onChange() {
			this.$emit('change', this.formData)
		},
		exRecord(major,minor='') {
			this.$emit('exRecord', 'Input',major,minor)
		},
		onNameBlur(val) {
			this.exRecord(`编辑${this.formType=='proposer'?'投保人':'被保人'}姓名`,val)
		},
		onHealthChange(val) {
			let text = this.healthCare.filter(it => it.value == val)[0].text
			this.exRecord('修改被保人参保类型',text)
		},
		changeTags(val,text) {
			this.formData.relationWithProposer = val
			this.formData.sameAsProposer = val==1?1:0
			this.exRecord('修改被保人关系',text)
			if(+this.companyCode == 9801) this.healthCareRB = healthCareRB
		},
		openIntroPopup(item) {
			let {text, value, intro} = item
			this.introInfo = {text, value, intro}
			this.introPopup = true
		},
		introConfirm() {
			this.formData.socialInsuranceType = this.introInfo.value
			this.introPopup = false
			this.socialTypePopup = false
		},
		mobileFormatter(val) {
			// return val.replace(/./g, function(word,ind) {
			// 	return (ind>2&&ind<7)?'*':word
			// })
			return val
		},
		mobileRules(val) {
			if (this.formData.maskData && val.indexOf("*")!=-1) return true
			if(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(val)) {
				this.exRecord(`编辑${this.formType=='proposer'?'投保人':'被保人'}手机号码`,val)
				return true
			}
			return false
		},
		idCardInput() {
			this.$emit('validIdCard')
		},
		validIdCard(val) {
			if (this.formData.identityType !== 11) return true
			if (this.formData.maskData && val.indexOf("*")!=-1) return true
			let message = (val && checkIdCard(val.toUpperCase())) || ''
			if (message === '验证通过') {
				if(this.insureds.filter(it => it.identityCode === val).length>1) {
					message = '证件号已存在'
					return false
				}
				let birthday = this.IdCardParsing(val,1)
				let age = this.IdCardParsing(val,3)
				let gender = this.IdCardParsing(val,2)
				this.formData.birthday = birthday
				this.formData.age = age
				this.formData.gender = gender
				this.formData.index = this.formData.identityType+val
				this.exRecord(`编辑${this.formType=='proposer'?'投保人':'被保人'}身份证号`,val)
				this.healthCareRB = healthCareRB
				return true
			} else {
				return false
			}
		},
		IdCardParsing(UUserCard, num) {  // 通过身份证号计算年龄、性别、出生日期
			if (num == 1) { //获取出生日期
				return (UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14));
			}
			if (num == 2) { //获取性别
				if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) { //男
					return 1;
				} else { //女
					return 2;
				}
			}
			if (num == 3) { //获取年龄
				var myDate = new Date();
				var month = myDate.getMonth() + 1;
				var day = myDate.getDate();
				var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
				if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
					age++;
				}
				return age;
			}
		},
		async onValidate(proposer) {
			let result = await this.$refs.insureForm.validate().then(res => {return true}).catch(err => {return false})
			if(result && this.formType == 'insureds' && +this.companyCode == 9801) {
				let params = {dto:{
					supplierId: 9801,
					applicantInfo: {
						name: proposer.name,
						certificateNo: proposer.identityCode,
						mobileTelephone: proposer.mobile
					},
					insuredInfo: {
						relationshipWithApplicant: this.formData.relationWithProposer,
						name: this.formData.relationWithProposer==1?proposer.name:this.formData.name,
						certificateNo: this.formData.relationWithProposer==1?proposer.identityCode:this.formData.identityCode,
						mobileTelephone: proposer.mobile
					}
				}}
				let resData = await this.$API.onQueryCBJY(params).then(res => {return res.Dto}).catch(err => {return false})
				if(resData === false) return false
				this.formData.transNo = resData.transNo
				let planCodeList = resData.planCodeList
				let healthCare = [],isCheck = false
				planCodeList.forEach(item => {
					healthCare.push(healthCareRB.filter(it => it.value==+item)[0])
					if(+item == this.formData.socialInsuranceType) {
						isCheck = true
						this.ishealthCareErr = false
					}
					// if(item != '0' || item != 0) {
					// 	healthCare.push(healthCareRB.filter(it => it.value==+item)[0])
					// 	if(+item == this.formData.socialInsuranceType) {
					// 		isCheck = true
					// 		this.ishealthCareErr = false
					// 	}
					// }
				})
				healthCare.sort((a,b)=>{
					return b.sort - a.sort
				})
				this.healthCareRB = healthCare
				if(!isCheck) {
					this.ishealthCareErr = true
					this.formData.socialInsuranceType = undefined
				}
				return isCheck
			}
			return result
			// return await this.$refs.insureForm.validate().then(res => {
			// 	return true
			// }).catch(err => {
			// 	return false
			// })
		},
		tagsDisabled(val) {
			if(this.disabled) return 'disabled'
			if((val == 1 || val == 2) && this.relationList.findIndex(it => {return it == val}) != -1) return 'disabled'
			if(val == 3 && this.relationList.filter(it => it == 3).length >= 4) return 'disabled'
		},
		async getHealthCareRB() {
			// this.$parent.methods()
			let validate = await this.onValidate()
			let list = [
				{ text: '公费医疗人保', value: 1 },
				{ text: '新市民人保', value: 2 },
				{ text: '基本医保人保', value: 3 },
			]
			this.healthCareRB = validate ? list : []
		},
		openFilePopup (key) {  // 打开投保信息说明文件模态框
		  this.$emit('openFilePopup', key)
		},
	}
}
</script>

<style lang="less" scoped>
.insureForm-main{
	padding: 12px;
}
.relation-box{
	margin: 24px 0;
	display: flex;
	align-content: center;
	justify-content: space-between;
	.tags-item{
		margin-right: 24px;
		font-size: 32px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		background-color: #F0F0F0;
		color: #666666;
		line-height: 32px;
		padding: 14px 24px 16px;
		border-radius: 8px;
		&.disabled{
			opacity: 0.4;
			pointer-events: none;
			background-color: #f0f0f0;
			color: #999999;
		}
		&.active{
			opacity: 1;
			background-image: url(../assets/images/home/select.png);
			background-size: 100% 100%;
			color: #FD3147;
		}
		&:last-child{
			margin-right: 0;
		}
	}
}
.insureForm-box{
	/deep/ .van-field__label{
		font-weight: bold;
	}
	/deep/ .van-dropdown-item__content{
		.van-cell__value{
			max-width: 40px;
		}
	}
	.proposerForm{
		/deep/ .van-cell{
			&:last-child{
				&::before{
					position: absolute;
					box-sizing: border-box;
					content: ' ';
					pointer-events: none;
					right: 16px;
					bottom: 0;
					left: 16px;
					border-bottom: 1px solid #ebedf0;
					-webkit-transform: scaleY(.5);
					transform: scaleY(.5);
					left: 0;
					right: 0;
				}
			}
		}
	}
	.dropdown-menu-box{
		position: relative;
		.placeholder{
			position: absolute;
			top: 0;
			left: 0;
			font-size: 24px;
			line-height: 22PX;
			color: #ee0a24;
			pointer-events: none;
		}
		/deep/ .van-dropdown-menu__title{
			height: 22px;
		}
	}
	.specialLabel {
		width: 100%;
		max-width: 200px;
		display: flex;
		align-items: center;
		span {
			display: block;
			white-space: nowrap;
			max-width: calc(100% - 44px);
			overflow: hidden;
			margin-right: 12px;
		}
	}
}
.socialTypePopup-box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.popup-tit {
		width: 100%;
		height: 88px;
		line-height: 100px;
		text-align: center;
	}
	.content-box {
		width: 100%;
		flex: 1;
		padding: 12px 36px;
		box-sizing: border-box;
		.intro-box {
			width: 100%;
			display: flex;
			align-items: center;
		}
		.look {
			width: 120px;
			margin-left: 12px;
			text-align: end;
		}
		.socialType-select-box {
			width: 100%;
			.socialType-item {
				width: 100%;
				padding: 24px;
				background-color: #efefef;
				margin-top: 24px;
				text-align: center;
				border-radius: 8px;
			}
		}
	}
}
.intro-text {
	width: 688px;
	padding: 24px 36px;
}
.introPopup-box {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.popup-tit {
		padding: 36px 12px 12px;
	}
	.intro-detail {
		padding: 24px;
		line-height: 36px;
		flex: 1;
	}
	.agreement {
		height: 64px;
		line-height: 64px;
		text-align: center;
		margin: 24px 24px 16px;
		background: linear-gradient(180deg, #FEEEEE 0%, #FEF5F4 100%);
		border-radius: 64px;
	}
	.option-box {
		display: flex;
		justify-content: space-around;
		padding: 12px 12px 36px;
		box-sizing: border-box;
		button {
			width: 288px;
			height: 80px;
		}
	}
}
</style>
